
import { defineComponent } from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import store from "@/store";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Footer from "@/components/Footer.vue";
import PaymentCard from "@/components/PaymentCard.vue";
import Tooltip from "primevue/tooltip";
import NotificationCard from "@/components/Notification-Card.vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Badge from "primevue/badge";
import ProgressSpinner from "primevue/progressspinner";

import Utils from "@/utility/utils";

export default defineComponent({
  name: "Invoice",
  components: {
    Card,
    Calendar,
    Dropdown,
    Footer,
    PaymentCard,
    NotificationCard,
    InputText,
    Button,
    DataTable,
    Column,
    Badge,
    ProgressSpinner,
  },

  directives: {
    tooltip: Tooltip,
  },
  data() {
    return {
      selectedCustomer: null,
      page: 1,
      first: 0,
      dueDateFilter: null,
      selectedStatus: null,
      selectedType: null,
      itemsPerPage: 10,
      stati: [
        { name: "Outstanding", code: "O" },
        { name: "Paid", code: "P" },
        { name: "Payment Pending", code: "Y" },
      ],
      types: [
        { name: "Credit Memo", code: "CM" },
        { name: "Debit Memo", code: "DM" },
        { name: "Invoice", code: "IN" },
        { name: "On Account", code: "OA" },
      ],
      sort: {
        sortBy: "",
        sortOrder: 1,
      },
      filters: {
        status: { value: [], matchMode: FilterMatchMode.IN },
        type: { value: [], matchMode: FilterMatchMode.IN },
        due_date: {
          operator: FilterOperator.AND,
          constraints: [{ value: [], matchMode: FilterMatchMode.BETWEEN }],
        },
        ar_id: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      hover: [],
    };
  },
  computed: {
    ...mapState(["invoice", "customer", "payment", "session"]),
    ...mapGetters({
      getInvoices: "invoice/getInvoices",
      getInvoiceView: "invoice/getInvoice",
      getLoadingInvoices: "invoice/getLoadingInvoices",
      getPage: "invoice/getPage",
      getClient: "session/getClient",
      getInvoicesToPay: "invoice/getInvoicesToPay",
      getLength: "invoice/getLength",
    }),
    ...mapState(["session", "sidemenu", "customer", "theme"]),
  },
  async created() {
    if (
      this.getLength === 0 &&
      store.getters["invoice/getDateFilter"] === null &&
      store.getters["invoice/getStatusFilter"] === null
    ) {
      await this.fetchFilteredInvoices2(false);
    }

    const dueDateFilterString = store.getters["invoice/getDateFilter"];
    if (dueDateFilterString !== null) {
      let dueDateFilterDate: any[] = [];
      dueDateFilterString.forEach((stringDate: any) => {
        dueDateFilterDate.push(new Date(stringDate));
      });
      (this.dueDateFilter as any) = dueDateFilterDate;
    }
    this.selectedStatus = store.getters["invoice/getStatusFilter"];
    this.itemsPerPage = store.getters["invoice/getItemsPerPage"];

    if (this.getLength >= this.itemsPerPage) {
      this.first = this.getPage * this.itemsPerPage;
    }
  },

  methods: {
    ...mapActions({
      fetchPDFInvoice: "invoice/getInvoice",
      setInvoice: "invoice/setInvoices",
      addInvoiceToPay: "invoice/addInvoiceToPay",
      removeInvoiceToPay: "invoice/removeInvoiceToPay",
      addNotification: "notification/add",
    }),
    removeInvoice(data: any): void {
      this.removeInvoiceToPay({
        arId: data.ar_id,
        balance: parseFloat(data.balance),
        correls: "cust_name",
      });
    },
    addInvoice(invoice: any): void {
      if (
        this.invoice.invoicesToPay.length == 0 ||
        (this.invoice.invoicesToPay.length > 0 &&
          invoice.cust == this.invoice.invoicesToPay[0].cust)
      ) {
        this.addInvoiceToPay({
          arId: invoice.ar_id,
          balance: parseFloat(invoice.balance),
          dueDate: invoice.due_date,
          cust: invoice.cust,
        });
      } else {
        this.addNotification({
          message: "Invoice belongs to a different customer",
          type: "error",
        });
      }
    },
    inPayArray(id: string): boolean {
      const found = this.getInvoicesToPay.find((inv: any) => {
        return inv.arId == id;
      });
      if (found) return true;
      return false;
    },
    getTypeTitle(type: string): string {
      return this.types.find((typ: any) => typ.code === type)?.name ?? "";
    },
    formatDate(date: string | Date): string {
      return Utils.formatDate(date);
    },
    async handleFilterByStatus(): Promise<void> {
      await this.fetchFilteredInvoices2(false);
    },
    async handleFilterByType(): Promise<void> {
      await this.fetchFilteredInvoices2(false);
    },
    async handleFilterByDueDate(): Promise<void> {
      if (
        !(this.dueDateFilter as any)[1] ||
        (this.dueDateFilter as any)[1] === null
      ) {
        (this.dueDateFilter as any)[1] = (this.dueDateFilter as any)[0];
      }
      await this.fetchFilteredInvoices2(false);
    },
    async handleFilterById(): Promise<void> {
      await this.fetchFilteredInvoices2(false);
    },
    async handleClearFilterByStatus(): Promise<void> {
      this.selectedStatus = null;
      await this.fetchFilteredInvoices2(false);
    },
    async handleClearFilterByType(): Promise<void> {
      this.selectedType = null;
      await this.fetchFilteredInvoices2(false);
    },
    async handleClearDueDateFilter(): Promise<void> {
      this.dueDateFilter = null;
      this.filters.due_date.constraints[0].value = null as any;
      await this.fetchFilteredInvoices2(false);
    },
    async handleClearFilterById(): Promise<void> {
      this.filters.ar_id.constraints[0].value = null;
      await this.fetchFilteredInvoices2(false);
    },
    sortInvoices(event: any): void {
      this.sort.sortOrder = event.sortOrder;
      this.sort.sortBy = event.sortField;
      this.fetchFilteredInvoices2(false);
    },
    invoiceIcon(id: string): any {
      let downloaded = this.getInvoiceView;
      let downloading = this.getLoadingInvoices.includes(id);
      return {
        "pi pi-download":
          downloaded.find((i: any) => i.invoice_num === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: any) => i.invoice_num === id) !== undefined &&
          !downloading,
      };
    },
    fetchInvoice(recordId: string): void {
      this.fetchPDFInvoice({
        recordId: recordId,
        Client: this.getClient,
      });
    },
    fetchCustomers(): void {
      store.dispatch("customer/getAllCustomers", {
        Client: this.getClient,
      });
    },

    fetchFilteredInvoices(addInvoices: boolean): void {
      store.dispatch("invoice/setInvoices", {
        custId: (this.selectedCustomer as any)?.cust_id,
        dateRange: this.dueDateFilter,
        status: this.selectedStatus,
        sortBy: this.sort.sortBy,
        addInvoices: addInvoices,
        Client: this.getClient,
        id: this.filters.ar_id.constraints[0].value,
        type: this.selectedType,
        correls: "cust_name",
      });
      if (!addInvoices) {
        this.page = 1;
        this.first = 0;
      }
    },
    async fetchFilteredInvoices2(addInvoices: boolean): Promise<void> {
      let sortBy;
      switch (this.sort.sortBy) {
        case "invoice_amt":
          sortBy = "invoice.amt";
          break;
        case "due_date":
          sortBy = "due.date";
          break;
        default:
          sortBy = this.sort.sortBy;
          break;
      }
      if (this.selectedStatus) {
        (this.filters.status.value as any) = [
          this.selectedStatus === "Y" ? "O" : this.selectedStatus,
        ];
      } else {
        (this.filters.status.value as any) = null;
      }

      if (this.selectedType) {
        (this.filters.type.value as any[]) = [this.selectedType];
      } else {
        this.filters.type.value = [];
      }

      await this.setInvoice({
        custId: (this.selectedCustomer as any)?.cust_id ?? "",
        dateRange: this.dueDateFilter,
        status: this.selectedStatus,
        sortBy: sortBy ?? "",
        addInvoices: addInvoices,
        Client: this.getClient,
        id: (this.filters.ar_id as any).value,
        type: this.selectedType,
        correls: "cust_name",
      });
      if (!addInvoices) {
        this.page = 1;
        this.first = 0;
      }
    },

    onPage(event: any): void {
      store.commit("invoice/NEXT_PAGE", event.page);
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1 ||
          event.page === event.pageCount - 2) &&
        this.getLength == store.getters["invoice/getRange"] - 100
      ) {
        this.fetchFilteredInvoices2(true);
      }
    },
  },
});
