
import { defineComponent } from "vue";
import Invoice from "@/components/Invoice.vue";
import store from "@/store";

export default defineComponent({
  components: {
    Invoice,
  },
  created() {
    store.dispatch("invoice/getAllCustomers", {
      Client: store.getters["session/getClient"],
    });
  },
});
